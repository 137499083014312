@charset "UTF-8";
/*$font-noto-sans-jp: true; // true or false
@if $font-noto-sans-jp {
  $font-style: 'Noto Sans JP', sans-serif;
}*/
/* ==========================================================================//
//
// [個別スタイル]
//
// ========================================================================== */
.p-top .p-acheivement {
  margin-top: 164px;
}

@media screen and (min-width: 897px) {
  .p-top .p-acheivement {
    width: calc(100% - 100px);
    margin: 164px auto 0;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement {
    margin-top: 74px;
  }
}

.p-top .p-acheivement .c-box-achievement__item {
  padding-bottom: 47px;
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__item {
    padding-bottom: 41px;
  }
}

.p-top .p-acheivement .c-box-achievement__item {
  margin-top: 0;
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__item + .c-box-achievement__item {
    margin-top: 19px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__item.-bg-blue {
    background: none;
    position: relative;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__item.-bg-blue.-space {
    margin-top: 0;
    padding-top: 0;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__item.-bg-blue.-space::after {
    content: '';
    width: 79%;
    height: calc(100% - 51px);
    display: block;
    position: absolute;
    left: unset;
    top: unset;
    right: 0;
    bottom: 0;
    background: #EAF9FF;
    z-index: -1;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__item.-bg-blue::after {
    content: '';
    width: 79%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: #EAF9FF;
    z-index: -1;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__title::before {
    display: none;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__title::after {
    content: '';
    width: 79%;
    height: calc(100% - 26px);
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #EAF9FF;
    z-index: -1;
  }
}

.p-top .p-acheivement .c-box-achievement__title-in .c-title {
  font-size: 9rem;
  font-family: "Teko", sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__title-in .c-title {
    font-size: 4.8rem;
    line-height: 0.9;
  }
}

.p-top .p-acheivement .c-box-achievement__title-in .c-sub-title {
  font-family: "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  margin-top: -5px;
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__title-in .c-sub-title {
    font-size: 1.5rem;
    line-height: 1.3;
    margin-top: 1px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__item-in .c-image {
    text-align: center;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement__item-in .c-image img.c-image {
    width: 70%;
    margin: 10px auto 0;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-acheivement .c-box-achievement .c-box-detail {
    margin-top: 20px;
  }
}

.p-top .p-contact-us {
  margin-top: 130px;
}

@media screen and (max-width: 896px) {
  .p-top .p-contact-us {
    margin-top: 50px;
  }
}

.p-top .p-reason-list {
  margin-top: 130px;
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list {
    margin-top: 59px;
  }
}

@media screen and (min-width: 897px) {
  .p-top .p-reason-list .c-box-title .c-heading-md {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-heading-lg {
    font-size: 4.8rem;
    text-align: center;
    line-height: 0.9;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-heading-md {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 0;
  }
}

.p-top .p-reason-list .c-box-content-grid-type {
  padding-bottom: 0;
}

.p-top .p-reason-list .c-box-content-grid-type::before {
  display: none;
}

.p-top .p-reason-list .c-box-content-grid-type__card {
  margin-top: 50px;
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-box-content-grid-type__card {
    margin-top: 29px;
  }
}

.p-top .p-reason-list .c-box-content-grid-type__card:nth-child(3), .p-top .p-reason-list .c-box-content-grid-type__card:nth-child(4) {
  margin-top: 26px;
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-box-content-grid-type__card .c-box-detail {
    padding: 10px 0 18px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-box-content-grid-type__card .c-title {
    padding: 0 15px 0;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-box-content-grid-type__card .c-label {
    margin-left: 0px;
    width: 80.8%;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-box-content-grid-type__card + .c-box-content-grid-type__card {
    margin-top: 23px;
  }
}

.p-top .p-reason-list .c-box-content-grid-type__image {
  padding-top: 30px;
  padding-right: 30px;
  position: relative;
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-box-content-grid-type__image {
    padding-top: 20px;
    padding-right: 20px;
  }
}

.p-top .p-reason-list .c-box-content-grid-type__image::after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 20px);
  display: block;
  position: absolute;
  z-index: -1;
  background: #EAF9FF;
  top: 0;
  left: 30px;
  right: -30px;
  bottom: 20px;
}

@media screen and (max-width: 896px) {
  .p-top .p-reason-list .c-box-content-grid-type__image::after {
    width: calc(100% - 20px);
    left: 21px;
  }
}

.p-top .p-reason-list .c-box-content-grid-type .c-box-detail .c-link {
  text-align: center;
  margin-top: 20px;
}

.p-top .p-price {
  margin-top: 150px;
}

@media screen and (max-width: 896px) {
  .p-top .p-price {
    margin-top: 61px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-heading-lg {
    font-size: 4.8rem;
    line-height: 0.9;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-heading-md {
    font-size: 1.5rem;
    margin-top: 0px;
  }
}

.p-top .p-price .c-box-slider {
  margin: 30px auto 0;
  position: relative;
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-box-slider {
    transform: scale(0.9);
    width: 100%;
    margin: 0 auto;
    margin-left: -4%;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-box-slider .c-box-price-object__card {
    padding: 0 10px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-box-slider .slick-list {
    overflow: visible;
  }
}

.p-top .p-price .c-box-slider .slick-arrow {
  width: 60px;
  height: 60px;
  border-radius: 110px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #1A1311;
  color: #1A1311;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.p-top .p-price .c-box-slider .slick-prev {
  left: -30px;
  z-index: 1;
}

.p-top .p-price .c-box-slider .slick-prev::before {
  content: '';
  background: #1A1311 url(/assets/_img/common/ico_arrow-2.svg) no-repeat center center;
  background-size: 14px 28px;
  display: block;
  width: 14px;
  height: 28px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  transform: rotate(180deg);
}

.p-top .p-price .c-box-slider .slick-next {
  right: -30px;
  z-index: 1;
}

.p-top .p-price .c-box-slider .slick-next::before {
  content: '';
  background: #1A1311 url(/assets/_img/common/ico_arrow-2.svg) no-repeat center center;
  background-size: 14px 28px;
  display: block;
  width: 14px;
  height: 28px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.p-top .p-price .c-box-price-object {
  margin-top: 0;
}

.p-top .p-price .c-box-price-object__card {
  width: 100%;
  padding: 0 10px;
}

.p-top .p-price .c-box-price-object__card .c-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-box-price-object__card .c-title {
    line-height: 1.55;
    padding: 6.35% 20% 6.35% 7.9%;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-box-price-object__card .c-title::after {
    right: 13px;
  }
}

.p-top .p-price .c-link {
  margin-top: 20px;
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-link {
    margin-top: 7px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-price .c-link .c-box-button-small__link {
    width: 79.55%;
  }
}

.p-top .p-parallax {
  width: calc(100% - 100px);
  margin: 56px auto 0;
}

.p-top .p-access {
  margin-top: 144px;
  padding-bottom: 107px;
}

@media screen and (max-width: 896px) {
  .p-top .p-access {
    margin-top: 90px;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-access .c-heading-lg {
    font-size: 4.8rem;
    line-height: 0.9;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-access .c-heading-md {
    font-size: 1.5rem;
  }
}

.p-top .p-access__in {
  width: calc(100% - 100px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__in {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
}

.p-top .p-access__in::before {
  content: '';
  width: 100%;
  height: 410px;
  display: block;
  position: absolute;
  top: 120px;
  background: #EAF9FF;
  z-index: -1;
  min-width: 0;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__in::before {
    top: 18px;
    height: 104.2%;
    left: -16px;
    width: 86%;
  }
}

.p-top .p-access__box-detail {
  width: 50%;
  flex: 0 1 50%;
  position: relative;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail {
    width: 100%;
    flex: 0 1 100%;
  }
}

@media screen and (min-width: 897px) {
  .p-top .p-access__box-detail.-map {
    flex: 0;
    position: absolute;
    left: 50px;
    top: 0;
    bottom: 0;
    width: calc(50% - 50px);
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail .c-heading-lg {
    text-align: center;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail .c-heading-md {
    text-align: center;
  }
}

.p-top .p-access__box-detail .c-map {
  padding-top: 52.365%;
  position: static;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail .c-map {
    width: 104.3%;
    flex: 0 1 104.3%;
    position: relative;
    height: auto;
    left: -15px;
    margin-top: 30px;
  }
}

.p-top .p-access__box-detail .c-map iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.p-top .p-access__box-detail.text {
  padding-top: 16px;
  width: 50%;
  flex: 0 1 50%;
  margin-left: 50%;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail.text {
    padding: 0;
    width: 100%;
    flex: 0 1 100%;
    margin-left: 0;
  }
}

.p-top .p-access__box-detail.text .c-detail {
  padding-top: 51px;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail.text .c-detail {
    padding-top: 20px;
  }
}

@media screen and (min-width: 897px) {
  .p-top .p-access__box-detail.text .c-detail {
    padding-left: 50px;
  }
}

.p-top .p-access__box-detail.text .c-detail .c-sub-title {
  font-size: 2rem;
  line-height: 1.5;
  display: inline-block;
  border-bottom: 2px solid #1A1311;
  padding-bottom: 2px;
  font-weight: 700;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail.text .c-detail .c-sub-title {
    font-size: 1.5rem;
    line-break: 1.55;
  }
}

.p-top .p-access__box-detail.text .c-detail .c-sub-title + .c-text {
  margin-top: 6px;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail.text .c-detail .c-sub-title + .c-text {
    margin-top: 11px;
  }
}

.p-top .p-access__box-detail.text .c-detail .c-text {
  line-height: 1.333;
}

.p-top .p-access__box-detail.text .c-detail .c-text:not(:first-of-type) {
  margin-top: 15px;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail.text .c-detail .c-text:not(:first-of-type) {
    margin-top: 10px;
  }
}

.p-top .p-access__box-detail.text .c-detail .c-tel {
  font-family: "Teko", sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  margin-top: 4px;
  padding-left: 27px;
  background: url(/assets/_img/common/ico_phone-1.svg) no-repeat left center;
}

.p-top .p-access__box-detail.text .c-detail .c-link {
  margin-top: 30px;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail.text .c-detail .c-link {
    text-align: center;
    margin-top: 11px;
  }
}

.p-top .p-access__box-detail.text .c-detail .c-link .c-box-button-small__link {
  padding: 18px 61px;
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail.text .c-detail .c-link .c-box-button-small__link {
    padding: 15px 0;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail .c-box-contact-us__button {
    margin-top: 14px;
    width: calc(100% - 45px);
    padding: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail .c-box-contact-us__button .c-button-contact--tel {
    min-height: 50px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail .c-box-contact-us__button .c-button-contact__in {
    padding: 10px 30px 10px 56px;
    background-position: 15px 3px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-access__box-detail .c-box-contact-us__button .c-button-contact__in .c-button-contact--tel {
    min-height: 50px;
  }
}

.p-top .p-sns {
  margin-top: 89px;
}

@media screen and (max-width: 896px) {
  .p-top .p-sns {
    margin-top: 73px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-sns__title {
    display: inline-block;
    margin: 0 auto;
    text-align: left;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-sns__instragram {
    text-align: center;
  }
}

.p-top .p-sns .c-heading-lg {
  display: inline-block;
  position: relative;
}

.p-top .p-sns .c-heading-lg::before {
  content: '';
  display: block;
  width: 57px;
  height: 57px;
  background: url(/assets/_img/common/instagram_logo-01.svg) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -72px;
  top: 15px;
}

@media screen and (max-width: 896px) {
  .p-top .p-sns .c-heading-lg::before {
    width: 31px;
    height: 31px;
    right: -39px;
    top: 4px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-sns__content {
    margin-top: 30px;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-sns + .p-contact-us {
    margin-top: 56px;
  }
}

.p-top .c-heading-md {
  font-size: 2.8rem;
  line-height: 1.428;
  margin-top: -10px;
}

@media screen and (max-width: 896px) {
  .p-top .c-heading-md {
    font-size: 1.5rem;
    margin-top: 0;
  }
}

.p-top .p-welcome__in {
  position: relative;
}

@media screen and (max-width: 896px) {
  .p-top .p-welcome__in::after {
    content: '';
    width: 79%;
    height: calc(100% - 139px - 112px);
    display: block;
    background: #EAF9FF;
    position: absolute;
    bottom: 112px;
    left: 0;
    z-index: -1;
  }
}

@media screen and (min-width: 897px) {
  .p-top .p-welcome__in .c-heading-lg.en {
    letter-spacing: -0.036em;
  }
}

@media screen and (max-width: 896px) {
  .p-top .p-welcome__box-detail::after {
    display: none;
  }
}

@media screen and (max-width: 896px) {
  .p-top + .l-footer {
    margin-top: 47px;
  }
}

.p-top .slick-slide .media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top .c-box-achievement__item-in .c-box-label {
  max-height: 48px;
}

@media screen and (max-width: 896px) {
  .p-top .c-box-achievement__item-in .c-box-label {
    height: 36px;
  }
}

.u-main-slider {
  height: 100vh;
  opacity: 0;
  transition: opacity 1s ease-in;
  /* item */
}

@media screen and (max-width: 896px) {
  .u-main-slider {
    height: auto;
  }
}

.u-main-slider.is-active {
  opacity: 1;
}

.u-main-slider .slider-track {
  transition: transform 9.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden;
}

.u-main-slider__item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.u-main-slider__bg {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: transform 9.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: scale(1.05);
}

@media screen and (max-width: 896px) {
  .u-main-slider__bg {
    height: auto;
    padding-top: 100%;
  }
}

.on .u-main-slider__bg {
  transform: scale(1);
}
